<template>
  <v-bottom-sheet v-model="sheet" inset max-width="500">
    <v-card tile>
      <v-card-text class="title pt-8 text-center">
        {{ item.url ? `Link «${item.title}»` : `Datei «${item.title}»` }}<br />
      </v-card-text>
      <v-card-text class="display text-center">
        <DocumentItemIcon :value="item" avatar />
        <br />

        <span v-if="item.fileName">
          {{ item.fileName }}
        </span>
        <span v-if="item.url">
          {{ item.url }}
        </span>
      </v-card-text>
      <v-card-text v-if="item.description" class="body-1 text-center">
        {{ item.description }}
      </v-card-text>
      <v-card-text class="pt-2 text-center">
        <v-chip class="mx-1"
          >{{ formatDate(item.lastModifiedDate) }},
          {{ item.lastModifiedTime }}</v-chip
        >
        <v-chip class="mx-1">{{
          item.visibility ? item.visibility.description : ''
        }}</v-chip>
        <v-chip class="mx-1" v-if="!item.url">{{ item.size }}</v-chip>
      </v-card-text>
      <v-divider />
      <v-list v-if="item.url">
        <v-list-item :href="item.url" target="_blank">
          <v-list-item-icon><v-icon>mdi-link</v-icon></v-list-item-icon>
          <v-list-item-content
            >Link in neuem Tab öffnen
          </v-list-item-content></v-list-item
        >
        <v-list-item @click="copyToClipboard(item.url)">
          <v-list-item-icon><v-icon>mdi-content-copy</v-icon></v-list-item-icon>
          <v-list-item-content
            >Link in die Zwischenablage kopieren
          </v-list-item-content></v-list-item
        >
      </v-list>
      <v-list v-else>
        <v-list-item
          @click="apiDocument({ id: item.id })"
          v-if="openFileExtensions.includes(item.fileExtension)"
        >
          <v-list-item-icon
            ><v-icon>mdi-file-document</v-icon></v-list-item-icon
          >
          <v-list-item-content>Datei öffnen</v-list-item-content></v-list-item
        >
        <v-list-item
          @click="apiDocument({ id: item.id, openInNew: true })"
          v-if="openFileExtensions.includes(item.fileExtension)"
        >
          <v-list-item-icon><v-icon>mdi-open-in-new</v-icon></v-list-item-icon>
          <v-list-item-content
            >Datei in neuem Tab öffnen</v-list-item-content
          ></v-list-item
        >
        <v-list-item @click="apiDocument({ id: item.id, download: true })">
          <v-list-item-icon><v-icon>mdi-download</v-icon></v-list-item-icon>
          <v-list-item-content
            >Datei herunterladen
          </v-list-item-content></v-list-item
        >
        <v-list-item
          @click="
            copyToClipboard(
              `https://intern.gymkirchenfeld.ch/document/${docId}`
            )
          "
        >
          <v-list-item-icon><v-icon>mdi-content-copy</v-icon></v-list-item-icon>
          <v-list-item-content
            >Link zur Datei in die Zwischenablage kopieren
          </v-list-item-content></v-list-item
        >
      </v-list>

      <v-divider />
      <v-card-actions>
        <v-btn @click="sheet = false" text block>schliessen</v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import DocumentItemIcon from './Components/DocumentItemIcon.vue';
import { formatDate } from 'common/utils/date';
import { copyToClipboard } from 'common/utils/helper.js';
export default {
  components: { DocumentItemIcon },
  props: ['docId'],
  data() {
    return {
      item: {},
      sheet: true,
      openFileExtensions: ['pdf', 'png', 'svg', 'jpg', 'jpeg', 'gif'],
    };
  },
  watch: {
    docId() {
      this.fetchData();
    },
    sheet() {
      if (!this.sheet) {
        window.setTimeout(() => this.$router.back(), 300);
      }
    },
  },
  methods: {
    copyToClipboard,
    formatDate,
    async fetchData() {
      if (this.docId) {
        this.loading = true;
        this.showDialog = true;
        this.item = await this.apiGet({
          resource: 'document/document',
          id: this.docId,
        });
        this.loading = false;
      }
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
